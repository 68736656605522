import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import 'font-awesome/css/font-awesome.min.css'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueWechatTitle from 'vue-wechat-title'

createApp(App)
  .use(store)
  .use(VueAxios, axios)
  .use(router)
  .use(ElementPlus)
  .use(VueWechatTitle)
  .mount('#app')
