window.onscroll = function () {
  // 获取距离页面顶部的距离
  const toTop = document.documentElement.scrollTop || document.body.scrollTop
  const header = document.getElementById('header')
  if (toTop > 80) {
    header && header.classList.add('headerHidden')
  } else if (toTop < 20) {
    header && header.classList.remove('headerHidden')
  }
}
