import axios from './http.js'
// 查询文章、标签、分类数量
const SELECTBLOGNUMS = (params) => axios.get('blog/selectBlogNums', params)
// 查询所有标签
const SELECTALLTAGS = (params) => axios.get('blog/selectAllTags', params)
// 获取文章
const SELECTARTICLES = (params) => axios.get('blog/selectArticles', { params })
// 查询所有分类
const SELECTALLCATEGORYS = (params) =>
  axios.get('blog/selectAllCategorys', params)
// 查询一篇文章
const SELECTARTICLEBYID = (params) =>
  axios.get('blog/selectArticleById', { params })
// 登录
const LOGIN = (params) => axios.post('user/login', params)
// 退出登录
const LOGOUT = (params) => axios.get('user/logout', params)
// 注册
const REGISTER = (params) => axios.post('user/insertUser', params)
// 给文章点赞
const LIKEARTICLE = (params) => axios.post('blog/likeArticle', params)
// 点赞状态查询
const SELECTARTICLELIKE = (params) =>
  axios.get('blog/selectArticleLike', { params })
// 新增评论
const INSERTARTICLECOMMENT = (params) =>
  axios.post('blog/insertArticleComment', params)
// 查询评论
const SELECTARTICLECOMMENT = (params) =>
  axios.get('blog/selectArticleComment', { params })
// 新增留言
const INSERTLEAVEWORD = (params) => axios.post('blog/insertLeaveWord', params)
// 查询留言
const SELECTLEAVEWORD = (params) =>
  axios.get('blog/selectLeaveWord', { params })
// 查询所有工具分类
const SELECTALLTOOLSCATEGORY = () => axios.get('blog/selectAllToolsCategory')
// 查询所有工具
const SELECTAllTOOLS = () => axios.get('blog/selectAllTools')

// 新增爱情故事
const INSERTLOVERECORD = (params) => axios.post('blog/insertLoveRecord', params)
// 查询爱情故事
const SELECTLOVERECORD = (params) =>
  axios.get('blog/selectLoveRecord', { params })
// 查询时光机
const SELECTTIMEMACHINE = () => axios.get('blog/selectTimeMachine')
const TEST = () => axios.get('https://v2.jinrishici.com/one.json?client=browser-sdk/1.2&X-User-Token=FFeZmAq%2BcjghqMd%2BNJxMY1DQ1KqCzy0M')

export {
  SELECTBLOGNUMS,
  SELECTALLTAGS,
  SELECTARTICLES,
  SELECTALLCATEGORYS,
  SELECTARTICLEBYID,
  LOGIN,
  LOGOUT,
  REGISTER,
  LIKEARTICLE,
  SELECTARTICLELIKE,
  INSERTARTICLECOMMENT,
  SELECTARTICLECOMMENT,
  INSERTLEAVEWORD,
  SELECTLEAVEWORD,
  SELECTALLTOOLSCATEGORY,
  SELECTAllTOOLS,
  INSERTLOVERECORD,
  SELECTLOVERECORD,
  SELECTTIMEMACHINE,
  TEST
}
