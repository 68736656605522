<template>
  <div id="nav">
    <Header v-if="$store.state.version == '1.0'"></Header>
  </div>
  <router-view />
  <Footer v-if="$store.state.version == '1.0'"></Footer>
</template>

<script>
import { ref } from '@vue/reactivity'
import Header from '../src/components/Header.vue'
import Footer from './components/Footer.vue'
import { getCurrentInstance, onMounted } from '@vue/runtime-core'
export default {
  components: {
    Header,
    Footer
  }
}
</script>
<style>
#nav {
  position: sticky;
  top: 0;
  z-index: 9;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: #000;
}

.router-link-active {
  text-decoration: none;
  color: #000;
}

:root {
  --subject-color: #99cccc;
  --el-border-radius-base: 0px !important;
}
</style>
