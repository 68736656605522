import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import store from '../store/index'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home_',
    component: () => import('../view/Home.vue'),
    meta: {
      title: ' 叶德钦的个人博客 | 首页',
      version: '2.0'
    }
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('../main/Home.vue'),
    meta: {
      title: ' 叶德钦的个人博客 | 首页',
      version: '1.0'
    }
  },
  {
    path: '/articleList',
    name: 'ArticleList',
    component: () => import('../main/ArticleList.vue'),
    meta: {
      title: ' 叶德钦的个人博客 | 文章',
      version: '1.0'
    }
  },
  {
    path: '/articleList_',
    name: 'ArticleList_',
    component: () => import('../view/ArticleList.vue'),
    meta: {
      title: ' 叶德钦的个人博客 | 文章',
      version: '2.0'
    }
  },
  {
    path: '/article',
    redirect: '/'
  },
  {
    path: '/article/id=:id',
    name: 'Article',
    component: () => import('../main/Article.vue'),
    meta: {
      title: ' 叶德钦的个人博客 | 文章',
      version: '1.0'
    }
  },
  {
    path: '/article_/id=:id',
    name: 'Article_',
    component: () => import('../view/Article.vue'),
    meta: {
      title: ' 叶德钦的个人博客 | 文章',
      version: '2.0'
    }
  },
  {
    path: '/messageBoard',
    name: 'MessageBoard',
    component: () => import('../main/MessageBoard.vue'),
    meta: {
      title: ' 叶德钦的个人博客 | 留言',
      version: '1.0'
    }
  },
  {
    path: '/messageBoard_',
    name: 'MessageBoard_',
    component: () => import('../view/MessageBoard.vue'),
    meta: {
      title: ' 叶德钦的个人博客 | 留言',
      version: '2.0'
    }
  },
  {
    path: '/tools',
    name: 'Tools',
    component: () => import('../main/Tools.vue'),
    meta: {
      title: ' 叶德钦的个人博客 | 工具',
      version: '1.0'
    }
  },
  {
    path: '/tools_',
    name: 'Tools_',
    component: () => import('../view/Tools.vue'),
    meta: {
      title: ' 叶德钦的个人博客 | 工具',
      version: '2.0'
    }
  },
  {
    path: '/loveRecord',
    name: 'LoveRecord',
    component: () => import('../main/LoveRecord.vue'),
    meta: {
      title: '爱的故事'
    }
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../view/About.vue'),
    meta: {
      title: ' 叶德钦的个人博客 | 关于',
      version: '2.0'
    }
  },
  {
    path: '/test',
    name: 'Test',
    component: () => import('../main/Test.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  store.commit('editNav', to.fullPath)
  if (store.state.version === '2.0') {
    if (to.meta.version === '1.0') {
      next('/')
    } else next()
  }
  if (store.state.version === '1.0') {
    if (to.meta.version === '2.0') {
      next('/home')
    } else next()
  }
})

export default router
