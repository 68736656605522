// 设置cookie
function setCookie(name: string, value: string | '' | null, days: number) {
  const date = new Date() // 获取时间
  date.setTime(date.getTime() + 24 * 60 * 60 * 1000 * days) // 保存的天数\
  window.document.cookie =
    name + '=' + value + ';path=/;expires=' + date.toUTCString()
}

// 查询cookie
function getCookie(name: string) {
  let arr
  const reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)')
  if ((arr = document.cookie.match(reg))) {
    return arr[2]
  } else {
    return null
  }
}

export { setCookie, getCookie }
