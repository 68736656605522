import { createStore } from 'vuex'
import { getCookie } from '../tools/cookie.ts'

export default createStore({
  state: {
    navActivate: '/',
    isLogin: Boolean(getCookie('is_login')),
    loginUser: '',
    activateInputId: 0,
    version: '2.0'
  },
  mutations: {
    // 切换版本
    switchVersion(state, version) {
      state.version = version
    },
    // 更新激活导航条菜单
    editNav(state, navName) {
      state.navActivate = navName
    },

    // 更新登录状态
    updateIsLogin(state, status) {
      state.isLogin = status
    },

    // 更新 评论/留言 激活id
    updateActivateInputId(state, id) {
      if (state.activateInputId === id) {
        state.activateInputId = 0
      } else {
        state.activateInputId = id
      }
    }
  },
  actions: {},
  modules: {}
})
