<template>
  <div
    id="main"
    class="fontColor"
  >
    <div class="container">
      <!-- 联系我 -->
      <div class="contact_me">
        <div class="contact_left">
          <span class="contact_title">联系我</span>
          <span class="email">yedeqin42@qq.com</span>
        </div>
        <div class="relativeClass">
          <img
            src="../assets/qq.png"
            alt="qq"
            class="icon"
            id="qq"
          />
          <div class="qqPopUp">
            <img
              src="../assets/qqQRcode.png"
              alt=""
              class="QRcodeImHeight"
            />
          </div>
        </div>
        <div>
          <img
            src="../assets/gitee.png"
            alt="gitee"
            class="icon"
          />
        </div>
        <div class="relativeClass">
          <img
            src="../assets/wechat.png"
            alt="wechat"
            class="icon"
            id="wechat"
          />
          <div class="wechatPopUp">
            <img
              src="../assets/wechatQRcode.png"
              alt=""
              class="QRcodeImHeight"
            />
          </div>
        </div>
      </div>
      <!-- 关于我 -->
      <div class="about_me">
        <span class="about_title">关于我</span>
        <span class="about_content">一个喜欢写代码的博主，喜欢去探索未知领域，喜欢学习新内容，喜欢在代码的世界中遨游。目标成为一名优秀的前端开发工程师。</span>
      </div>

      <hr class="footer_hr" />

      <div id="buttomRight">
        <!-- 版权所有 备案号-->
        <div class="fontSizeSmall">
          Copyright © 2021-2022 blog.yedeqin.com All Rights Reserved. &nbsp;&nbsp;闽ICP备2021009610号-1
        </div>
        <!-- 转载注明 -->
        <div
          class="fontSizeMini"
          id="reprint"
        >
          转载内容版权归作者及来源网站所有，本站原创内容转载请注明来源。
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {}
</script>

<style src="../css/bottom.css" scoped>
</style>
